import React from "react";
import ReactPlayer from "react-player";

export const GameplayTrailerBlock = () => {
	return (
		<div className="dreamworld-video-block">
			<div className="text-wrapper">GAMEPLAY TRAILER</div>
			<div className="video-wrapper">
				<ReactPlayer
					className="video"
					url="https://www.youtube.com/watch?v=VIwL3vzt4XE"
					width="96.6%"
					height="93.8%"
					controls={true}
				></ReactPlayer>
			</div>
		</div>
	);
};
