import React from "react";

export const CommunityGuidelinesTextBlock = () => {
	return (
		<div className="legal-block">
			<div className="text-block">
				<p class="p1">
					<b>
						DREAMWORLD- CONTENT Standards AND COMMUNITY GUIDELINES
					</b>
				</p>
				<p class="p2">
					<b>Effective: June 18, 2021</b>
				</p>
				<p class="p3">
					These Content Standards and Community Guidelines (these “
					<b>Standards and</b> <b>Guidelines</b>”) apply to all users
					and/or visitors of the Software, any and all User Content
					submitted or uploaded in connection with the access and/or
					use of the Software (or any part thereof).
					<span class="Apple-converted-space"> </span>These Standards
					and Guidelines are a part of and incorporated into the{" "}
					<a href="https://www.playdreamworld.com/pages/terms-of-use">
						<span class="s1">DreamWorld Terms of Use</span>
					</a>{" "}
					(the “<b>Terms</b>”).
					<span class="Apple-converted-space"> </span>Capitalized
					terms used herein but not defined will have the meanings
					given in the Terms.
				</p>
				<ol class="ol1">
					<li class="li4">
						<span class="s2">
							<b>Content Standards</b>
						</span>
						<span class="s3">
							:<span class="Apple-converted-space"> </span>User
							Content must in its entirety comply with the Terms,
							including, without limitation these Standards and
							Guidelines, and all applicable federal, state, local
							and international laws and regulations. Without
							limiting the foregoing, User Content must not
							contain anything that is or could appear to:{" "}
						</span>
					</li>
				</ol>
				<ul class="ul1">
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be threatening, abusive, bullying, harmful, stalking
							another user, harassing, or hateful;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be offensive, obscene, indecent, inflammatory or
							otherwise objectionable;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be untrue, misleading, libelous, defamatory,
							invasive to another person’s privacy or protected
							data;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be or promote sexually explicit or pornographic
							material, violence, or discrimination based on race,
							ethnicity, sex, religion, nationality, disability,
							sexual orientation or age;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be plagiarized, or infringes any patent, trademark,
							trade secret, copyright or other intellectual
							property or other rights of any other person;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">Be likely to deceive any person;</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Promote any illegal activity, or advocate, promote
							or assist any unlawful act.
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Cause annoyance, inconvenience or needless anxiety
							or be likely to upset, embarrass, alarm or annoy any
							other person;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Impersonate any person, or misrepresent your
							identity or affiliation with any person or
							organization;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be anything that you do not have the legal right to
							transfer;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be anything that you are legally bound not to
							disclose under contractual or fiduciary
							relationships including, but not limited to, inside
							information or proprietary and confidential business
							information;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be unsolicited, undisclosed, or unauthorized
							advertising;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be software viruses or any other malicious computer
							code, files, or programs designed to interrupt,
							destroy, or limit the functionality of any computer
							software, hardware, or telecommunication equipment;
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be data or information obtained by the unauthorized
							access of another’s computer or account with which
							such a posting or submission would constitute
							unauthorized use; and/or
						</span>
					</li>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							Be any other post or submission that violates
							applicable local, state, national or international
							law, regulation or statute (including export laws).
						</span>
					</li>
				</ul>
				<ol class="ol1">
					<li class="li4">
						<span class="s2">
							<b>Community Guidelines</b>
						</span>
						<span class="s3">
							.<span class="Apple-converted-space"> </span>When
							you use the Software and/or interact with others
							through or in connection with the use of the
							Software, including through any interactive
							communication methods available through or in
							connection with the use of the Software, be
							respectful and follow DreamWorld’s policies.
							<span class="Apple-converted-space"> </span>Treat
							others as you would want to be treated and be
							polite. Freedom of speech and having an opinion does
							not mean you can be abusive, hateful, or disruptive.
							<span class="Apple-converted-space"> </span>The
							following sets forth general (but by no means
							exhaustive) guidelines to follow when using the
							Software and/or interacting with other users of the
							Software:
						</span>
					</li>
				</ol>
				<ol class="ol1"></ol>
				<ul class="ul1">
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							<b>
								<i>Bullying and harassment</i>
							</b>
							:
						</span>
					</li>
					<ul class="ul1">
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not participate in hate speech or be hateful
								to others based on their race, ethnicity,
								religion, profession, appearance, medical
								condition, sexual orientation or preference or
								any other discriminatory behavior.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not target individuals to degrade, harass,
								bully, threaten, stalk or shame them.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not post or disclose personal information
								about others unless you have explicit consent
								from the person.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not be disruptive, vulgar, abusive, or
								sexually explicit in your posts, messages, or
								chats or otherwise in the use of the Software.
							</span>
						</li>
					</ul>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							<b>
								<i>Sexual or adult-oriented behavior</i>
							</b>
							:
						</span>
					</li>
					<ul class="ul1">
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not use sexually explicit, vulgar, or obscene
								language.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not engage in sexual solicitation.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not engage in adult-themed or sexually
								oriented activities.
							</span>
						</li>
					</ul>
					<li class="li4">
						<span class="s4"></span>
						<span class="s3">
							<b>
								<i>Spam, scam, fraud</i>
							</b>
							:
						</span>
					</li>
					<ul class="ul1">
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not misrepresent or lie about your age and
								(and report users who appear to be lying about
								theirs!).
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not impersonate any person or entity
								(including, without limitation, DreamWorld or
								any DreamWorld staff), use a fictitious name, or
								falsely state or otherwise misrepresent your
								affiliation with any person or entity
								(including, without limitation, DreamWorld or
								any DreamWorld staff).
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not ask for another user's password, log-in
								credentials, Access Code, or any Account
								information.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not share, give away, trade, or sell your
								Account, usernames and/or avatar, or your Access
								Code to the Software.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not post links to fraudulent websites or
								phishing sites.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not harvest user names, phone numbers,
								addresses, or, email addresses or otherwise
								collect or attempt to collect any other personal
								information of any users.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not post or engage in any unsolicited or
								unauthorized advertising, promotional materials,
								'junk mail', 'spam', 'chain letters', 'pyramid
								schemes', ‘multi-level marketing schemes’, or
								any other form of such solicitation.
							</span>
						</li>
						<li class="li4">
							<span class="s5"></span>
							<span class="s3">
								Do not engage or behave in any manner that is
								detrimental to the enjoyment of the Software by
								other users, including, but not limited to,
								“griefing,” cheating, game abandonment, game
								sabotage, scamming, and/or social engineering.
							</span>
						</li>
					</ul>
				</ul>
				<ol class="ol1">
					<li class="li5">
						<span class="s2">
							<b>Consequences of Violation</b>
						</span>
						<span class="s3">
							. Without limiting any other rights or remedies we
							may have, if we determine that you have violated any
							part of these{" "}
						</span>
						<span class="s6">Standards and Guidelines</span>
						<span class="s3">
							, we may remove any offending content or suspend or
							terminate your access and/or use of the Software (or
							any part thereof). We may intercept or block any
							content or traffic where the Software is being used
							unlawfully or not in accordance with the Terms
							and/or these{" "}
						</span>
						<span class="s6">Standards and Guidelines</span>
						<span class="s3">
							. Our right to suspend or terminate your access
							and/or use of the{" "}
						</span>
						<span class="s6">Software</span>
						<span class="s3">
							{" "}
							applies even if a violation is committed
							unintentionally or without your authorization.
						</span>
					</li>
					<li class="li5">
						<span class="s7"></span>
						<span class="s2">
							<b>Reporting</b>
						</span>
						<span class="s3">
							. If you become aware of any violation of these{" "}
						</span>
						<span class="s6">Standards and Guidelines</span>
						<span class="s3">
							, please immediately notify us and provide us with
							assistance, as requested, to stop or remedy the
							violation. To report any violation of these
							Standards and Guidelines, please contact us at
							[email].
						</span>
					</li>
				</ol>
				<p class="p4">
					<b>QUESTIONS</b>
				</p>
				<p class="p4">
					If you have any questions about these Standards and
					Guidelines, please contact us by email at:
					<span class="Apple-converted-space"> </span>
					<a href="mailto:support@playdreamworld.com">
						<span class="s1">support@playdreamworld.com</span>
					</a>
					.
				</p>
			</div>
		</div>
	);
};
