import Typography from "@mui/material/Typography";
import { CTAButton } from "../CTAButton";
import { useDispatch } from "react-redux";
import {
	accountWidgetActions,
	incrementStageType,
} from "../../login/accountWidgetSlice";
import { Icon } from "@mui/material";

function SignupCompleteBoxZealy() {
	return (
		<>
			{/* Sign up box */}
			<Typography
				className="alpha-text"
				sx={{ padding: "20px 20px 80px 20px" }}
			>
				Welcome to DreamWorld, Dreamer! Join our Zealy and be one of the
				first 100 trailblazers to reach Level 2 to unlock Alpha 2
				access, starting with our weekly playtesting sessions! Don't
				just dream, play now!
			</Typography>

			{/* CTA BUTTON */}
			<CTAButton
				CTAButtonText="JOIN US ON ZEALY"
				className="CTA-instance"
				link="https://zealy.io/c/playdreamworld"
				newTab={true}
			/>
		</>
	);
}

function SignupCompleteBoxDownload(dispatch) {
	const onDownloadClick = () => {
		window.open(
			"https://playdreamworld.b-cdn.net/LauncherInstaller/DreamWorldInstaller_L07.exe",
			"_blank"
		);
		dispatch(accountWidgetActions(incrementStageType()));
	};

	return (
		<>
			{/* Sign up box */}
			<Typography
				className="alpha-text"
				sx={{ padding: "10px 20px 60px 20px" }}
			>
				Welcome to DreamWorld!
				<br></br>
				<br></br>
				Click the link below to Download our launcher (Windows only) and
				use the credentials you just created to log in.
				<br></br>
				<br></br>
				Be on the lookout for an email from{" "}
				<u>support@playdreamworld.com</u> for more info!
			</Typography>

			{/* CTA BUTTON */}
			<CTAButton
				CTAButtonText="DOWNLOAD GAME"
				className="CTA-instance"
				onClick={onDownloadClick}
			/>
		</>
	);
}

export function DownloadCompleteJoinDiscordBox() {
	return (
		<>
			{/* Sign up box */}
			<Typography
				className="alpha-text"
				sx={{ padding: "20px 20px 80px 20px" }}
			>
				While DreamWorld downloads, join our friendly Discord community
				to chat with the developers and fellow players!
			</Typography>

			{/* <Icon sx={{ width: "300px", height: "100px" }}>
				<img
					style={{ width: "100%", height: "100%" }}
					src="../assets/img/new_design/SocialMedia/Discord_logo.svg"
				></img>
			</Icon> */}
			{/* CTA BUTTON */}
			<CTAButton
				CTAButtonText="JOIN THE DISCORD"
				className="CTA-instance"
				link="https://discord.gg/7MUByhwEub"
				newTab={true}
			/>
		</>
	);
}

export function WaitlistBox() {
	return (
		<>
			{/* Sign up box */}
			<Typography
				className="alpha-text"
				sx={{ padding: "0px 20px 50px 20px" }}
			>
				Thank you so much for joining the DreamWorld waitlist.
				<br></br>
				<br></br>
				We are currently working on the next big Alpha update, but when
				it’s ready you will be first in line to play it!
				<br></br>
				<br></br>
				Also please join our Discord community by clicking the link
				below.
			</Typography>

			{/* CTA BUTTON */}
			<CTAButton
				CTAButtonText="DISCORD"
				className="CTA-instance"
				link="https://discord.gg/7MUByhwEub"
				newTab={true}
			/>
		</>
	);
}

export function SignupCompleteBox(dispatch) {
	return SignupCompleteBoxDownload(dispatch);
}
