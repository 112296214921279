import React from 'react';
import './App.css';
import {createTheme, ThemeProvider} from '@mui/material';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ConfirmProvider} from 'material-ui-confirm';
import NewDesignHome from "./pages/new_design/HomePage";
import CommunityPage from './features/community/CommunityPage';
import PurchasePage from './features/purchase/PurchasePage';
import HomePage from './features/home/HomePage';
import { AboutPage } from './pages/new_design/AboutPage';
import PressPage from './features/press/PressPage';
import PurchaseSuccessPage from './features/purchase-success/PurchaseSuccessPage';
import PurchaseCancelPage from './features/purchase-cancel/PurchaseCancelPage';
import NewPost from './components/create-post/NewPost';
import EditPost from './components/edit-post/EditPost';
import PostPage from './features/community/PostPage';

import { ContactPage } from './pages/contact_us/ContactPage';

import { TermsOfUsePage } from './pages/legal/TermsOfUse';
import { PrivacyPolicyPage } from './pages/legal/PrivacyPolicy';
import { CookiePolicyPage } from './pages/legal/CookiePolicy';
import { CommunityGuidelinesPage } from './pages/legal/CommunityGuidelines';
import { FoundersPackPage } from './pages/founders_pack/FoundersPackPage';
import { RecoveryPage } from './pages/new_design/RecoveryPage';
import {showFounders, theme} from './theme.js'



function App() {
    const foundersPages =
    <>
        <Route path="purchase" element={<FoundersPackPage PurchaseSuccess={false}/>} />
        <Route path="purchase_success" element={<FoundersPackPage PurchaseSuccess={true}/>} />
    </>

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<NewDesignHome />} />
            <Route path="invite" element={<NewDesignHome />} />

            {/* <Route path="oldhome" element={<HomePage/>} /> */}

            <Route path="about" element={<AboutPage />} />
            <Route path="press" element={<PressPage />} />
            <Route path="contact" element={<ContactPage/>} />

            {/* Founders Pack */}
            {
                showFounders ? foundersPages : null
            }

            {/* <Route path="purchase" element={<PurchasePage />} /> */}
            {/* <Route path="success" element={<PurchaseSuccessPage />} /> */}
            {/* <Route path="cancel" element={<PurchaseCancelPage />} /> */}

            {/* Recovery Pages */}
            <Route path="recover-password" element={<RecoveryPage PageType="recover"/>} />
            <Route path="password-reset"  element={<RecoveryPage PageType="reset"/>} />
            <Route path="register-trial-alpha2"  element={<RecoveryPage PageType="registration"/>} />


            {/* Blog Pages */}
            <Route path="community" element={<CommunityPage />} />
            <Route path="editpost/:id" element={<EditPost />} />
            <Route path="blogpost/:id" element={<PostPage />} />
            <Route path="newpost" element={<NewPost />} />




            {/* Legal Pages */}
            <Route path="privacy-policy" element={<PrivacyPolicyPage/>} />
            <Route path="terms-of-use" element={<TermsOfUsePage/>} />
            <Route path="cookie-policy" element={<CookiePolicyPage/>} />
            <Route path="content-standards-and-community-guidelines" element={<CommunityGuidelinesPage/>} />
          </Routes>
        </BrowserRouter>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
