import React from "react";
import PictureWText from "./PictureWText";
import { DiscordBlock } from "./DiscordBlock";
import { SignupBlock } from "./SignupBlock";
import { LinksBlock } from "../footer/LinksBlock";
import { NewFooter } from "../footer/NewFooter";
import { NewHeader } from "../header/NewHeader";
import { IntroBlock } from "./IntroBlock";
import { LoginBlock } from "./LoginBlock";
import { WeeklyUpdatesBlock } from "./WeeklyUpdatesBlock";
import { FoundersPackBlock } from "./FoundersPackBlock";
import { showFounders } from "../../theme";

function HomeIndex() {
	return (
		<>
			{/* header-area */}
			<NewHeader></NewHeader>
			{/* main-area */}
			<main>
				<LoginBlock></LoginBlock>
				<IntroBlock></IntroBlock>
				{showFounders ? <FoundersPackBlock></FoundersPackBlock> : null}
				<PictureWText
					title="Explore a Vibrant World"
					text="Embark on an adventure across diverse landscapes, from enchanted forests to bustling cities. Venture into uncharted territories filled with mystery and treasures."
					backgroundIndex={1}
					left={false}
				/>
				<PictureWText
					title="Build Amazing Structures"
					text="Unleash your architectural prowess and construct jaw-dropping structures. Create awe-inspiring castles, intricate bridges, and gravity-defying towers."
					backgroundIndex={2}
					left={true}
				/>
				<PictureWText
					title="Connect, Collaborate, Conquer"
					text="Join forces with others to build grandiose projects. Form alliances and team up, leaving your permanent mark on the world."
					backgroundIndex={4}
					left={false}
				/>
				<PictureWText
					title="Battle Valiant Foes"
					text="
							Test your skills in epic battles against fearsome
							monsters and colossal bosses. "
					backgroundIndex={3}
					left={true}
				/>

				<DiscordBlock></DiscordBlock>
				<WeeklyUpdatesBlock bShowFull={false}></WeeklyUpdatesBlock>
				<SignupBlock></SignupBlock>
				<LinksBlock></LinksBlock>
			</main>
			{/* main-area-end */}
			<NewFooter />
		</>
	);
}

export default HomeIndex;
