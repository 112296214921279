import React from "react";

export const TermsOfUseTextBlock = () => {
	return (
		<div className="legal-block">
			<div className="text-block">
				<p class="legal-text">
					<strong>DREAMWORLD TERMS OF USE</strong>
					<br />
					Last Updated: April 29, 2022
				</p>
				<p class="legal-text">
					<br />
					DreamWorld Realities, Inc. a Delaware corporation
					(hereinafter, “DreamWorld,” “we,” “us,” and/or “our”) owns
					and operates the interactive software game DreamWorld
					(hereinafter, the “Software”). These Terms of Use (the
					“Terms”) govern your use of the Software, including any (1)
					add-on content, materials, functionality, and/or features of
					the Software we make available for purchase and/or use in
					the Software (collectively, “Add-ons”), and/or (2) any new
					and/or enhanced versions of the Software that we make
					available to users for additional fees (collectively, “New
					Versions”). For the avoidance of doubt, the term “Software”
					as used herein includes Add-ons and New Versions.
				</p>
				<p class="legal-text">
					<br />
					IMPORTANT -- PLEASE READ THESE TERMS CAREFULLY. THESE TERMS
					SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS BETWEEN
					YOU AND DREAMWORLD GOVERNING YOUR USE OF THE SOFTWARE. BY
					PURCHASING A LICENSE TO THE SOFTWARE AND/OR ANY ADD-ONS, OR
					OTHERWISE USING OR ACCESSING THE SOFTWARE IN ANY MANNER,
					INCLUDING, BUT NOT LIMITED TO, DOWNLOADING OR INSTALLING THE
					SOFTWARE YOU: (1) ACKNOWLEDGE THAT YOU HAVE READ,
					UNDERSTAND, AND AGREE TO BE BOUND BY ALL OF THE TERMS AND
					CONDITIONS SET FORTH IN THESE TERMS, INCLUDING, WITHOUT
					LIMITATION, ALL OF THE ADDITIONAL TERMS SPECIFIED IN SECTION
					2); (2) REPRESENT AND AFFIRM THAT YOU MEET THE ELIGIBILITY
					REQUIREMENTS IN SECTION 1 BELOW; AND (3) YOU AGREE THAT YOU
					ARE ENTERING INTO THESE TERMS (INCLUDING ALL OF THE TERMS
					AND CONDITIONS SPECIFIED OR REFERENCED BELOW) WITH
					DREAMWORLD REALITIES, INC.
				</p>
				<p class="legal-text">
					<br />
					NOTICE OF AGREEMENT TO ARBITRATE: THESE TERMS CONTAIN AN
					AGREEMENT TO ARBITRATE WHICH WILL REQUIRE YOU TO SUBMIT
					CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION
					AND A WAIVER OF YOUR RIGHT TO PARTICIPATE IN CLASS ACTIONS
					AS SET FORTH IN, AND SUBJECT TO, SECTION 14.
				</p>
				<p class="legal-text">
					<br />
					NOTICE OF WARRANTY &amp; LIABILITY DISCLAIMERS: PLEASE BE
					ADVISED THAT DREAMWORLD DOES NOT PROVIDE ANY WARRANTIES TO
					YOU AND THESE TERMS LIMIT OUR LIABILITY TO YOU. PLEASE SEE
					SECTIONS 8.5 AND 12 FOR FURTHER INFORMATION.
				</p>
				<p class="legal-text">
					<br />
					IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS SET
					FORTH IN THESE TERMS OR DO NOT MEET THE ELIGIBILITY
					REQUIREMENTS, DO NOT PURCHASE OR OTHERWISE OBTAIN A LICENSE
					TO THE SOFTWARE, DO NOT DOWNLOAD AND/OR INSTALL THE
					SOFTWARE, AND DO NOT OTHERWISE ACCESS OR USE THE SOFTWARE.
				</p>
				<p class="legal-text">
					<br />
					ELIGIBILITY. You must be at least 18 years old or the age of
					majority in your jurisdiction purchase a license to the
					Software and to create and account to access and use the
					Software (an “Account”). Minors under 18 and at least 13
					years of age or the equivalent age as specified by law in
					the applicable jurisdiction (e.g., 16 years of age if you
					are located in the European Economic Area (EEA)) are
					permitted to access and/or use the Software and create an
					Account only if they have the appropriate permission from,
					and are under the direct supervision of, their parent or
					legal guardian who meets the eligibility requirements above.
					You affirm and represent and warrant, : (a) that you are at
					least 18 years old or the age of majority in your
					jurisdiction, and fully able and competent to enter into the
					terms, conditions, obligations, affirmations,
					representations, and warranties set forth in these Terms,
					and (b) if applicable, that you are the parent or legal
					guardian of a minor user that is at least13 years of age or
					the equivalent age as specified by law in the applicable
					jurisdiction and you are directly supervising the use of the
					Software by such minor user.
				</p>
				<p class="legal-text">
					<br />
					PRIVACY. If you provide us any personal information in
					connection with your access and/or use of the Software,
					DreamWorld will process such personal information as
					described in these Terms and DreamWorld’s Privacy Notice. By
					using the Software, you acknowledge and agree that
					DreamWorld can process your information in accordance with
					these Terms and as set forth in the Privacy Notice.
				</p>
				<p class="legal-text">
					<br />
					ADDITIONAL TERMS. When using the Software, and/or other
					particular features, services, content, or materials through
					or in connection with the Software, you may be required to
					accept and agree to additional terms, policies and/or
					operating rules and procedures in order to use the Software
					and/or such other features, services, content, or materials
					(the “Additional Terms”). If you do not accept and/or agree
					to such Additional Terms, you may not be able to use or
					continue using the Software and/or the applicable features,
					services, content, or materials to which such Additional
					Terms apply. For the avoidance of doubt, DreamWorld’s
					Privacy Notice and DreamWorld’s Content Standards &amp;
					Community Guidelines (hereinafter, the “Standards and
					Guidelines”) are deemed “Additional Terms” as such term is
					used herein. All such Additional Terms are hereby
					incorporated by reference into these Terms. In the event the
					provisions of any Additional Terms conflict with these
					Terms, the Additional Terms shall control solely with
					respect to the subject matter covered by such Additional
					Terms.
				</p>
				<p class="legal-text">
					<br />
					DREAMWORLD’S RIGHT TO CHANGE THESE TERMS &amp; THE SOFTWARE
					<br />
					Modifications to these Terms. DreamWorld reserves the right
					to update or modify these Terms at any time. Except as
					otherwise stated in this Section 4.1, all updates and
					modifications to these documents will be effective from the
					day they are posted online at
					https://www.playdreamworld.com/pages/terms-of-use. If we
					make any material changes to these Terms, we will post a
					notice of the changes on our website at
					www.playdreamworld.com (the “Site”), and if we have an email
					address on file for you, we may notify you of these changes
					by sending a notification to such email address. It is your
					responsibility to regularly visit and review these Terms. If
					you do not agree to any updates or modifications to these
					Terms, simply do not use or access the Software and
					terminate your Account, if applicable. Your continued use of
					the Software after we have posted the revised Terms, or, in
					the event of material changes, ten (10) days following the
					date DreamWorld first notified you of such material changes
					either through the Site, and/or via email, as applicable,
					signifies to us that you acknowledge and agree to be bound
					by the revised Terms.
				</p>
				<p class="legal-text">
					<br />
					Changes to the Software. DreamWorld reserves the rights to
					either temporarily or permanently modify, suspend or
					discontinue the Software (or any part thereof, including,
					without limitation, any old or pervious version of the
					Software), in whole or in part, with or without notice. You
					agree that DreamWorld will not be liable to you or to any
					third party for any modification, suspension or
					discontinuance of the Software (or any part thereof).
				</p>
				<p class="legal-text">
					<br />
					TERM; TERMINATION
					<br />
					Term. These Terms will remain in full force and effect as
					long as you continue to access or use the Software (or any
					part thereof), or until terminated in accordance with the
					provisions of these Terms.
				</p>
				<p class="legal-text">
					<br />
					Termination. You may terminate these Terms at any time by
					providing DreamWorld written notice of such termination to
					support@playdreamworld.com; subject line: “Notice of
					Termination.” At any time, DreamWorld may suspend or
					terminate your rights to access or use the Software (or any
					part thereof), or terminate these Terms with respect to you
					if, at DreamWorld’s reasonable determination, you have used
					the Software (or any part thereof), and/or or any other
					material or services provided by DreamWorld: (a) in
					violation of these Terms, including any incorporated
					guidelines, terms or rules; or (b) in a manner that violates
					laws, creates an excessive burden or potential adverse
					impact on DreamWorld’s systems.
				</p>
				<p class="legal-text">
					<br />
					Effect of Termination. Upon termination of these Terms, your
					Account and your right to use the Software will
					automatically terminate, and you agree to cease all use of
					the Software and permanently delete the Software, and all
					copies thereof, from all your computer devices.
				</p>
				<p class="legal-text">
					<br />
					Survival. Please note that even if these Terms are
					terminated, the rights and obligations applicable to you and
					DreamWorld under the following Sections shall survive any
					termination: 1, 2, 3, 4, 5.3, 5.4, 6, 7.2, 7.3, 7.6, 7.7,
					7.8, and 8 through 16.
				</p>
				<p class="legal-text">
					<br />
					ACCESS CODES; ACCOUNTS, USERNAMES &amp; CONSENT TO
					ELECTRONIC COMMUNICATIONS
					<br />
					Access Code. Please note that in order to download and/or
					install the Software, you must have the access code provided
					by DreamWorld to you when you purchased or otherwise
					obtained a license to the Software (the “Access Code”).
					<br />
					Accounts. To access and use certain features of the Software
					you may need to create and register an Account. In
					registering an Account, you agree to provide and maintain up
					to date information that is true, accurate, current, up to
					date, and complete. You agree that you will not (a) create
					an Account using a false identity or information, and (b)
					create an Account or use the Software if you have been
					previously removed or banned by us from use of the Software,
					or any part thereof. You acknowledge and agree that you may
					only register one individual Account at any given time for
					your use of the Software. Without limiting the foregoing,
					DreamWorld reserves the right to limit the number of
					Accounts that can be created from any one computer device
					and the number of computer or mobile devices that can access
					an individual Account. In addition, without limiting any
					other rights or remedies of DreamWorld under these Terms
					and/or any Additional Terms, DreamWorld reserves the right
					to terminate any or all multiple Accounts controlled by a
					single individual and/or Accounts that are inactive for an
					extended period of time.
					<br />
					Account Security. You understand and agree that you are
					solely responsible for maintaining the confidentiality of
					and protecting your password to your Account and your Access
					Codes (collectively, your “Credentials”). You are solely
					responsible for any activity originating from your Account,
					regardless of whether such activity is authorized by you.
					You agree to notify us immediately of any unauthorized use
					of your Account /or any breach of security, loss, theft or
					unauthorized use of your Credentials.
				</p>
				<p class="legal-text">
					<br />
					Usernames. You may be required to create a “username” or an
					“avatar” in connection with the use of the Software. You
					understand and agree that your username and/or avatar are
					linked to your Account and will be publicly displayed, as
					such, you should refrain from using identifying information
					(such as your name or an image of yourself) in your username
					or avatar. In addition, you understand and agree that you
					may not use a username and/or an avatar which is already in
					use by someone else or which does not meet the requirements
					of these Terms (including without limitation the Standards
					and Guidelines). We reserve the right to refuse registration
					of, or cancel, any username and/or avatar in our sole
					discretion.
					<br />
					Electronic Communications. By using the Software, you
					consent to receiving electronic communications from
					DreamWorld, including, but not limited to, communications
					sent via email or notifications posted on our Site. These
					electronic communications may include notices about
					applicable fees and charges, transactional information and
					other information concerning or related to your use of the
					Software. These electronic communications are part of your
					relationship with DreamWorld and you receive them as part of
					your purchase. You agree that any notices, agreements,
					disclosures or other communications that we send you
					electronically will satisfy any legal communication
					requirements, including that such communications be in
					writing.
				</p>
				<p class="legal-text">
					<br />
					LICENSE TO THE SOFTWARE
					<br />
					License to the Software. Subject to your compliance with
					these Terms (including, without limitation, the Standards
					and Guidelines and any other applicable Additional Terms and
					payment of applicable fees), DreamWorld hereby grants you a
					personal, non-exclusive, non-transferable,
					non-sublicensable, revocable, limited right and license to
					install and use the Software on a computer device owned
					and/or controlled by you, solely for your personal,
					non-commercial use, and otherwise in accordance with these
					Terms.
				</p>
				<p class="legal-text">
					<br />
					Prohibited Use. You agree that you will not, and will not
					permit any other person to: <br />
					Modify, adapt, translate or create derivative works based on
					the Software (or any part thereof), or any related
					documentation;
				</p>
				<p class="legal-text">
					<br />
					Reverse engineer, decompile, disassemble, or otherwise
					derive or determine or attempt to derive or determine the
					source code (or the underlying ideas, algorithms, structure
					or organization) of the Software, except as expressly
					permitted by applicable law;
				</p>
				<p class="legal-text">
					<br />
					Distribute, license, sublicense, assign, transfer or
					otherwise make available to any third party the Software,
					any related documentation or your Access Code;
					<br />
					Remove, alter, or obscure in any way any proprietary rights
					notices (including copyright notices) of DreamWorld or its
					suppliers on or within the Software, or related
					documentation;
				</p>
				<p class="legal-text">
					<br />
					Use the Software to store or transmit malicious code or
					infringing, libelous, or otherwise unlawful or tortious
					material, or to store or transmit material in violation of
					third-party privacy rights;
				</p>
				<p class="legal-text">
					<br />
					Interfere with or disrupt the integrity or performance of
					the Software or any system, network or data;
				</p>
				<p class="legal-text">
					<br />
					Attempt to gain unauthorized access to the Software (or any
					part thereof) or content, or its related systems or networks
					or;
				</p>
				<p class="legal-text">
					<br />
					Frame or utilize framing techniques to enclose the Software,
					Content, or any portion thereof;
				</p>
				<p class="legal-text">
					<br />
					create, use and/or distribute “auto”, “trainer”, “script” or
					“macro” computer programs or other “cheat” or “hack”
					programs or software applications for the Software (whether
					in an online multiplayer game or in a single player game
					over the internet or in local area network);
				</p>
				<p class="legal-text">
					<br />
					Use any meta tags, "hidden text", robots, spiders, crawlers,
					or other tools, whether manual or automated, to collect,
					scrape, index, mine, republish, redistribute, transmit,
					sell, license or download the Software or content, or the
					personal information of others without our prior written
					permission or authorization;
					<br />
					Impersonate any person or entity, or falsely state or
					otherwise misrepresent your affiliation with any person or
					entity;
				</p>
				<p class="legal-text">
					<br />
					Use the Software in violation of these Terms, the Content
					Standards and Community Guidelines, and/or any other
					Additional Terms; or
					<br />
					Use the Software in violation of any applicable local,
					state, national or international law, including, without
					limitation, any and all applicable export laws.
					<br />
					Reservation of Rights. DreamWorld reserves all rights and
					licenses not expressly granted to you in these Terms and no
					implied license is granted by DreamWorld. You acknowledge
					and agree that your use of the Software does not confer on
					you any interest, monetary or otherwise, in any aspect or
					feature of the Software, including but not limited to any
					in-game rewards, achievements, character levels, game
					progress, or in-game purchases or, unless you have paid
					applicable fees, the right to access any New Versions and/or
					Add-ons.
				</p>
				<p class="legal-text">
					<br />
					Updates, Upgrades and Patches. DreamWorld may provide
					patches, updates, or upgrades to the Software (collectively,
					“Updates”) that must be installed in order for you to
					continue to use the Software. DreamWorld may remotely apply
					and/or implement Updates without notifying you, and you
					hereby consent to DreamWorld applying such Updates. For the
					avoidance of doubt, Updates are included in the defined term
					“Software.” Notwithstanding the foregoing, you acknowledge
					and agree that DreamWorld does not have any maintenance or
					support obligations with respect to the Software.
				</p>
				<p class="legal-text">
					<br />
					License Verification &amp; Performance Monitoring. Please
					note that the Software may contain automated reporting
					routines that will automatically identify and analyze
					certain aspects of use and performance of Software and/or
					the systems on which they are installed, as well as the
					operator and operating environment (including features used
					and problems and issues that arise in connection therewith)
					and provide reports to DreamWorld. DreamWorld uses such
					information to improve the Software and for license
					verification. By using the Software, you expressly agree to
					such monitoring and automated reporting.
				</p>
				<p class="legal-text">
					<br />
					Additional Use Limitations and Usage Data. You acknowledge
					that DreamWorld may establish general practices and limits
					concerning the use of the Software, including without
					limitation the maximum period of time that data or other
					content will be retained by DreamWorld and/or available
					through the Software and the maximum storage space that will
					be allotted on DreamWorld's servers on your behalf. You also
					acknowledge that any character data, game progress, game
					customization or other data related to your use of the
					Software may cease to be available to you at any time
					without notice from DreamWorld, including without limitation
					after an Update is applied by DreamWorld. You agree that
					DreamWorld has no responsibility or liability for the
					deletion or failure to store any data or other content
					uploaded, transmitted, maintained, stored and/or processed
					through or in connection with the use of the Software,
					including, without limitation, following any Update. You
					further acknowledge that DreamWorld reserves the right to
					change these general practices and limits at any time, in
					its sole discretion, with or without notice.
				</p>
				<p class="legal-text">
					<br />
					Responsibility for Equipment and Internet. You acknowledge
					and agree that your use of the Software is dependent upon a
					computer and other hardware and equipment. In addition,
					access to certain functionality and features of the
					Software, such as, by way of example only and without
					limitation, the ability to save game progress, achievements,
					and/or interactions with other users, requires appropriate
					telecommunications and Internet services. You shall be
					solely responsible for acquiring and maintaining all
					telecommunications, network and Internet services and other
					hardware and software required to access and use the
					Software, including, without limitation, any and all costs,
					fees, expenses, and taxes of any kind related to the
					foregoing. DreamWorld shall not be responsible for any loss
					or corruption and/or loss of any content and/or data, or any
					other loss or damage of any kind arising from any such
					network and Internet services and/or any other hardware and
					software required to access and use the Software.
					<br />
					Open Source Components. The Software may contain third party
					software, including, without limitation, open source
					software components that are licensed under separate license
					terms and conditions (collectively, “Open Source
					Components”). Open Source Components are not licensed under
					these Terms, but are instead licensed under the terms of the
					applicable license agreement for such Open Source
					Components, which are located in a text file called “License
					Text” in the installation package of the Software. Your use
					of Open Source Components is subject to the terms of each
					applicable license. Notwithstanding any provision herein to
					the contrary, you are solely responsible for your compliance
					with such licenses. As used in these Terms, the defined term
					“Software” does not include Open Source Components.
				</p>
				<p class="legal-text">
					<br />
					USER CONTENT AND USER INTERACTIONS
					<br />
					User Content. Subject to the licenses you grant us in these
					Terms, as between DreamWorld and you, you will retain
					ownership of any messages, photos, video, audio, images,
					data, information, text and/or any other content or
					materials that you post, submit, transmit, and/or upload, or
					otherwise provide us, in connection with the use of the
					Software, including, but not limited to, your use of any
					voice chat features enabled through the Software
					(collectively, “User Content”). Please be advised that, any
					User Content posted or submitted to or in connection with
					public chats, events, contests, forums, message boards, or
					other communication tools through the Software will be
					considered non-confidential and non-proprietary. In
					addition, please note that if you enable or turn-on the
					voice chat feature while using the Software, the voice chat
					feature and your microphone will remain on as the default
					setting for your current session and any subsequent sessions
					until you disable or turn-off voice chat. You can view your
					microphone and voice chat options in the settings menu of
					the Software.
				</p>
				<p class="legal-text">
					<br />
					Responsibility for User Content. You acknowledge and agree
					that you, and not DreamWorld, are solely responsible for any
					User Content submitted, transmitted and/or contributed by
					you, including the legality, reliability, accuracy and
					appropriateness of such User Content. By providing User
					Content, you represent and warrant that: (a) you own or
					control all rights in and to User Submission, and have the
					necessary rights to grant the licenses granted to DreamWorld
					in Sections 8.4 below; (b) you have obtained all permissions
					and/or approvals as may be necessary or required to transmit
					User Content, or any personally identifiable information
					therein, in connection with the use of the Software; and (c)
					all of your User Content does and will comply with these
					Terms, including, without limitation, the Standards and
					Guidelines.
				</p>
				<p class="legal-text">
					<br />
					Violations of Standards and Guidelines. DreamWorld takes
					conduct and use violations very seriously. If you post,
					submit or otherwise provide User Content or engage in
					behavior in any manner in violation of these Terms,
					including, without limitation, the Standards and Guidelines,
					or in a way that DreamWorld deems to be unacceptable (in its
					sole discretion), such use may result in immediate account
					suspension or cancellation and the possibility that
					DreamWorld will pursue civil remedies. In addition, without
					limiting any other rights or remedies of DreamWorld
					hereunder, DreamWorld retains the right to remove any User
					Content that it deems, in its sole discretion, unacceptable
					and/or in violation of these Terms, including, without
					limitation, the Standards and Guidelines.
				</p>
				<p class="legal-text">
					<br />
					License to User Content. By providing User Content, you
					grant DreamWorld a worldwide, non-exclusive, royalty-free,
					fully paid, transferable right and license (including
					through the use of subcontractors) to copy, reproduce, use,
					host, store, transfer, publicly display, publicly perform,
					transmit, reproduce, modify (for the purpose of formatting
					for display), and distribute your User Content, in whole or
					in part, in connection with your use of the Software, and as
					reasonably necessary to provide the Software to you and
					other users of the Software. DreamWorld will not review,
					share, distribute, or reference any User Content except as
					provided in these Terms, the Privacy Notice, or as may be
					required by law.
				</p>
				<p class="legal-text">
					<br />
					Interactions &amp; Disputes with Users. DreamWorld does not
					conduct any verification of its users, and does not, and
					cannot, represent, warrant or guarantee any such person or
					entity’s identity or whether such person or entity is
					trustworthy. You are solely responsible for making your own
					decisions about the suitability of others with whom you
					contact and/or interact with through the Software. You
					acknowledge and agree that your interactions and dealings
					with other users of the Software, are solely between you and
					such other users, and any disputes between you and any other
					user(s) must be resolved solely between you and the other
					user(s). DreamWorld is not responsible for any loss, harm of
					damage of any sort incurred by you or any other user as a
					result of such interactions, dealings, communications and/or
					disputes, and DreamWorld has no obligations to become
					involved in any resulting dispute. YOU, ON BEHALF OF
					YOURSELF AND YOUR HEIRS, SUCCESSORS, ASSIGNS,
					REPRESENTATIVES, AGENTS, AND ANYONE ELSE CLAIMING BY OR
					THROUGH YOU, HEREBY EXPRESSLY RELEASE, DISCHARGE, AND HOLD
					DREAMWORLD AND ITS EMPLOYEES, AGENTS, REPRESENTATIVES,
					SHAREHOLDERS, OFFICERS, DIRECTORS, AFFILIATES, CONTRACTORS,
					SUCCESSORS OR ASSIGNEES (THE “RELEASED PARTIES”) HARMLESS
					FROM, AND WAIVE ANY AND ALL CLAIMS (INCLUDING BUT NOT
					LIMITED TO CLAIMS FOR PROPERTY DAMAGE, PERSONAL INJURY, OR
					DEATH), DEMANDS, AND DAMAGES OF EVERY KIND OR NATURE, KNOWN
					OR UNKNOWN, SUSPECTED OR UNSUSPECTED, DISCLOSED OR
					UNDISCLOSED, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR
					interactions, dealings, communications and/or disputes with
					other users. IF YOU ARE A CALIFORNIA RESIDENT, YOU SHALL AND
					HEREBY WAIVE, ON BEHALF OF YOURSELF AND YOUR SUCCESSORS,
					ASSIGNS, REPRESENTATIVES, EMPLOYEES, AGENTS, CLIENTS, AND
					ANYONE ELSE CLAIMING BY OR THROUGH YOU, CALIFORNIA CIVIL
					CODE SECTION 1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT
					EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT
					TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
					WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
					SETTLEMENT WITH THE DEBTOR.”
				</p>
				<p class="legal-text">
					<br />
					PURCHASE &amp; PAYMENT TERMS
					<br />
					Transactions. When you place and order to purchase a license
					to access the Software and/or place an order to purchase
					access to New Versions and/or Add-ons (each, an "Order"),
					you will be asked to supply certain information relevant to
					your Order including, without limitation, your credit card
					number, the expiration date of your credit card (and CCV#),
					your billing address, and your shipping information. You
					represent and warrant that you have the legal right to use
					any credit card(s) or other payment method(s) utilized in
					connection with any Order. By submitting such information,
					you grant DreamWorld the right to provide such information
					to our third party service providers for purposes of
					facilitating the completion of Orders initiated by you or on
					your behalf. Verification of your information may be
					required prior to the acknowledgment or completion of any
					Order.
				</p>
				<p class="legal-text">
					<br />
					Orders. You agree that by placing an Order, you are entering
					into a binding contract with DreamWorld and agree to pay all
					charges that may be incurred by you or on your behalf in
					connection with your Order, at the price(s) in effect when
					such charges are incurred. In addition, you remain
					responsible for any taxes that may be applicable to your
					Order.
				</p>
				<p class="legal-text">
					<br />
					Order Acceptance; Cancellation. We reserve the right to
					accept or reject any Order in our own discretion. Should we
					elect to accept your Order, you will receive a confirming
					email at the email address that you provide at such time.
				</p>
				<p class="legal-text">
					Notwithstanding, we reserve the right to cancel any Order
					once accepted by us at any time in our sole discretion.
					Additionally, you have the option of cancelling your Order
					(in its entirety only) at any time prior to our having sent
					to you the confirming email referenced herein.
				</p>
				<p class="legal-text">
					<br />
					Electronic Delivery. If you have purchased a license to the
					Software, then following DreamWorld’s acceptance of your
					Order, DreamWorld will issue an Access Code along with
					instructions to download and/or install the Software to the
					email provided on the Order. The Access Code and Software
					(and any Updates) shall be delivered by DreamWorld solely in
					electronic form. Add-ons will be made available remotely
					online through the Software and associated with your
					Account. Notwithstanding anything contained in these Terms
					and/or the applicable Order, you acknowledge and agree that
					all delivery dates are estimates only. Risk of loss passes
					to you upon the date DreamWorld issues the Access Code to
					you and/or otherwise makes available the Software to you
					(including, without limitation, any New Versions and/or
					Add-ons purchased by you). The Software (including any
					Updates) and any New Versions and/or Add-ons purchased by
					you hereunder will be deemed accepted upon DreamWorld making
					the Software, or the relevant Update, New Versions and/or
					Add-on, as applicable, available to you.
					<br />
					Our Right to Limit and/or Restrict Orders and/or
					Transactions. Without limiting our rights in Section 9.3, we
					reserve the right, with or without prior notice, to do any
					one or more of the following: (a) limit the available
					quantity of in-game purchases and/or discontinue the
					Software and/or products, content and/or materials offered
					as in-game purchases; (b) impose conditions on the honoring
					of any coupon, coupon code, promotional code, or other
					similar promotion; (c) bar any user from making or
					completing any or all Order(s); and (d) refuse to provide
					any user with the Software and/or in-game purchases.
				</p>
				<p class="legal-text">
					<br />
					Payment Terms. By submitting an Order, you agree to, and
					shall, pay DreamWorld, and hereby expressly authorize
					DreamWorld and its third party payment processors to charge
					you for, the fees specified in the Order submitted by you to
					DreamWorld, in accordance with the payment terms set forth
					in these Terms and as specified in the Order. The fees are
					exclusive of all applicable sales, use, value-added and
					other taxes, or other similar charges, and you will be
					responsible for payment of all such taxes (other than taxes
					based on DreamWorld’s income), and any related penalties and
					interest, arising from the payment of the fees, the delivery
					of the Software, and/or any other products, content and/or
					materials provided by DreamWorld hereunder, or performance
					of any services by DreamWorld.
				</p>
				<p class="legal-text">
					<br />
					No Refunds or Returns. THE FEES PAID BY YOU FOR LICENSES TO
					THE SOFTWARE AND/OR ADD-ONS ARE NON-RETURNABLE AND
					NON-REFUNDABLE. You agree not to make any chargebacks,
					and/or deny or reverse any Orders made by you hereunder, and
					you will reimburse DreamWorld for, and indemnify and hold
					DreamWorld harmless from and against, any chargebacks,
					denial or reversal of any Orders you make and any loss
					suffered by us arising therefrom.
				</p>
				<p class="legal-text">
					<br />
					Changes in Fees &amp; Payment Terms. DreamWorld reserves the
					right to change its fees and payment terms at its
					discretion; provided however, DreamWorld will provide you
					reasonable prior notice of any changes to the fees that may
					affect the Software you have purchased, including through
					notices posted on our Site or sent to the e-mail address we
					have on file to you.
				</p>
				<p class="legal-text">
					<br />
					DreamWorld’s Proprietary Rights
					<br />
					Ownership. As between you and DreamWorld, DreamWorld and its
					licensors retain all right, title and interest in and to the
					Software (including any and all Updates, New Version, and
					Add-ons), and (except for your User Content) any and all
					content, messages, data, text, graphics, images, photos,
					music, audio, video, works of authorship of any kind, and
					information or other materials that are posted, generated,
					provided or otherwise made available through the Software,
					and any and all modifications, enhancements and updates
					thereto. All DreamWorld trademarks are strictly owned by
					DreamWorld, and nothing in these Terms will be construed to
					transfer ownership rights or grant any permission, license
					or other rights to any DreamWorld trademark without written
					authorization from DreamWorld. The trademarks, service
					marks, logos, and/or names of individuals, companies and/or
					products mentioned through the Software may be the
					trademarks of their respective owners. The Software (and its
					underlying technology), and all parts thereof, are protected
					by copyright, trademark, patent, intellectual property, and
					other laws of the United States and foreign countries.
					<br />
					Feedback. You acknowledge that any ideas, inventions,
					suggestions for improvement or discussions submitted by you
					regarding any aspect of the Software, including, without
					limitation, the functioning, features, and other
					characteristics thereof (“Feedback”) may be used by
					DreamWorld without compensation or attribution to you, and
					you hereby grant DreamWorld, its subsidiaries, affiliates
					and partners a worldwide, irrevocable, royalty free,
					non-exclusive, sublicensable and transferable license under
					all your intellectual property rights in and to such
					Feedback, for DreamWorld to use and exploit for any purpose.
					<br />
					INDEMNIFICATION – OR WHAT HAPPENS IF WE GET SUED. We hope
					this never happens, but if DreamWorld gets sued because of
					something that you did, you agree to defend and indemnify
					us. This means that you agree to defend DreamWorld, its
					employees, contractors, and/or agents, and hold us harmless
					from and against any and all claims, actions, demands,
					liabilities, losses, damages, expenses, and costs (including
					reasonable attorney fees) arising from (a) your breach of
					these Terms, including, without limitation any Additional
					Terms, (b) your use or misuse of the Software (or any part
					thereof), (c) your User Content, (d) your breach of any law
					or the intellectual property and/or privacy rights of a
					third party, or (e) any disputes between you and any other
					user. We reserve the right, at our own expense, to assume
					the exclusive defense and control of any matter otherwise
					subject to indemnification by you hereunder, in which case,
					you agree to cooperate with us in the defense of the claim.
					Some jurisdictions limit consumer indemnities, so some
					portions or all of this indemnity provision may not apply to
					you.
				</p>
				<p class="legal-text">
					<br />
					DISCLAIMERS &amp; LIMITATIONS ON OUR LIABILITY
					<br />
					No Warranties. THE SOFTWARE (AND ALL PARTS THEREOF),
					ADD-ONS, AND/OR ANY OTHER CONTENT MATERIALS OR SERVICES
					PROVIDED BY DREAMWORLD, ARE PROVIDED "AS IS" AND "AS
					AVAILABLE". DREAMWORLD AND ITS LICENSORS AND SUPPLIERS
					HEREBY EXPRESSLY DISCLAIM ANY REPRESENTATIONS, WARRANTIES OR
					GUARANTEES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING
					WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR ANY
					PARTICULAR PURPOSE, SATISFACTORY PURPOSE, ACCURACY, OR
					NON-INFRINGEMENT. DREAMWORLD AND ITS LICENSORS AND SUPPLIERS
					DO NOT WARRANT OR MAKE ANY GUARANTEE THAT DEFECTS WILL BE
					CORRECTED OR THAT THE SOFTWARE (OR ANY PART THEREOF),
					ADD-ONS, OR ANY OTHER CONTENT, MATERIALS OR SERVICES
					PROVIDED BY DREAMWORLD: (A) WILL MEET YOUR REQUIREMENTS; (B)
					WILL BE COMPATIBLE WITH YOUR HOME NETWORK, COMPUTER DEVICE,
					OR ANY THIRD PARTY PRODUCTS OR SERVICES; (C) WILL BE
					AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE
					BASIS; OR (D) WILL BE ACCURATE OR RELIABLE. NO ADVICE OR
					INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
					DREAMWORLD, ANY THIRD PARTY, OR THROUGH THE SOFTWARE, SHALL
					CREATE ANY WARRANTY. This paragraph will apply to the
					maximum extent permitted by applicable law.
				</p>
				<p class="legal-text">
					<br />
					Limitation of Liability. TO THE MAXIMUM EXTENT PERMITTED BY
					APPLICABLE LAW, IN NO EVENT SHALL DREAMWORLD BE LIABLE OR
					OBLIGATED, WITH RESPECT TO THESE TERMS, THE SOFTWARE (OR ANY
					PART THEREOF), ADD-ONS, AND ANY OTHER content, MATERIALS
					AND/OR SERVICES PROVIDED BY DREAMWORLD, WHETHER UNDER ANY
					CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER LEGAL OR
					EQUITABLE THEORY AND EVEN IF DREAMWORLD HAS BEEN ADVISED OF
					THE POSSIBILITY OF SUCH LIABILITY OR OBLIGATION: (A) IN THE
					AGGREGATE, FOR ANY AMOUNTS IN EXCESS OF THE FEES PAID TO BY
					YOU TO DREAMWORLD IN THE SIX (6) MONTH PERIOD IMMEDIATELY
					PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR LIABILITY,
					OR ONE HUNDRED DOLLARS ($100.00); (B) FOR ANY COST OF
					PROCUREMENT OF SUBSTITUTE GOODS, TECHNOLOGY, SERVICES OR
					RIGHTS; (C) FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY,
					PUNITIVE, RELIANCE, OR CONSEQUENTIAL DAMAGES; (D) FOR
					INTERRUPTION OF USE OR LOSS OR CORRUPTION OF DATA; OR (E)
					FOR ANY MATTER BEYOND DREAMWORLD’S REASONABLE CONTROL. THE
					PARTIES AGREE THAT THESE LIMITATIONS SHALL APPLY EVEN IF
					THESE TERMS OR ANY LIMITED REMEDY SPECIFIED HEREIN IS FOUND
					TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. DREAMWORLD
					DISCLAIMS ALL LIABILITY OF ANY KIND OF DREAMWORLD’S
					AFFILIATES, LICENSORS AND SUPPLIERS.
				</p>
				<p class="legal-text">
					<br />
					Application of Disclaimers and Limitation of Damages on
					Consumers. Certain states and/or jurisdictions do not allow
					the exclusion of implied warranties or limitation of
					liability for incidental or consequential damages with
					respect to consumers (i.e., a person acquiring goods
					otherwise than in the course of a business), so the
					disclaimers, exclusions and limitations set forth in this
					Section 12 above may not apply to you if you are a consumer.
					The limitations or exclusions of warranties and liability
					contained in these Terms do not affect or prejudice the
					statutory rights of a consumer. The limitations or
					exclusions of warranties and remedies contained in these
					Terms shall apply to you as a consumer only to the extent
					such limitations or exclusions and remedies are permitted
					under the laws of the jurisdiction where you are located.
				</p>
				<p class="legal-text">
					<br />
					Basis of the Bargain. The warranty disclaimer and limitation
					of liability set forth above in this Section 12 are
					fundamental elements of the basis of the agreement between
					DreamWorld and you. DreamWorld would not be able to provide
					the Software on an economic basis without such limitations.
					The warranty disclaimer and limitation of liability inure to
					the benefit of DreamWorld’s suppliers.
					<br />
					AVAILABILITY OF THE SOFTWARE. Information describing the
					Software is accessible worldwide but this does not mean the
					Software, or certain portions thereof, are available in your
					country. DreamWorld may restrict access to the Software, or
					portions thereof, in certain countries in its sole
					discretion. It is your responsibility to make sure your use
					of the Software is legal in your country of residence. The
					Software may not be available or accessible in all
					languages.
				</p>
				<p class="legal-text">
					<br />
					GOVERNING LAW AND DISPUTE RESOLUTION
					<br />
					Governing Law. These Terms shall be governed in all respects
					by and construed in accordance with the laws of the State of
					California, without regard to its conflicts of laws
					principles. The United Nations Convention on Contracts for
					the International Sale of Goods in its entirety is expressly
					excluded from these Terms. Furthermore, these Terms
					(including without limitation, the Software) will not be
					governed or interpreted in any way by referring to any law
					based on the Uniform Computer Information Transactions Act
					(UCITA) or any other act derived from or related to UCITA.
				</p>
				<p class="legal-text">
					<br />
					Disputes. Except as otherwise set forth in these Terms, you
					agree that any dispute between you and DreamWorld arising
					out of or relating to these Terms, the Software (or any part
					thereof, including, without limitation, any Updates, New
					Versions, and/or Add-ons), and/or any content, materials,
					and/or services provided by DreamWorld hereunder
					(collectively, “Disputes”) shall be governed by the
					provisions set forth in this Section.
				</p>
				<p class="legal-text">
					<br />
					Notice of Disputes. A party who intends to seek arbitration
					or bring other action permitted under this Section 14, must
					first send a written notice of the Dispute to the other
					party by certified U.S. Mail or by Federal Express
					(signature required) or, only if that other party has not
					provided a current physical address, then by electronic mail
					(“Dispute Notice”). DreamWorld’s address for Dispute Notices
					is: DreamWorld Realities, Inc., Attention: Notice of
					Dispute,{" "}
					<span data-mce-fragment="1">
						440 N BARRANCA AVE #2952 COVINA, CA 91723
					</span>
					. The Dispute Notice must: (a) describe the nature and basis
					of the claim or dispute; and (b) set forth the specific
					relief sought.
					<br />
					Informal Resolution. You and DreamWorld agree to use good
					faith efforts to resolve the Dispute directly, but if we do
					not reach an agreement to do so within 30 days after the
					Dispute Notice is received, you or DreamWorld may commence
					an arbitration proceeding or other permitted action, as set
					forth in this Section 14.
					<br />
					Agreement to Arbitrate. In the interest of resolving
					Disputes between you and DreamWorld in the most expedient
					and cost-effective manner, and except as described in
					Section 14.9, you and DreamWorld agree that every dispute
					arising in connection with these Terms will be resolved by
					binding individual (not class) arbitration (the “Agreement
					to Arbitrate”). Arbitration is less formal than a lawsuit in
					court. Arbitration uses a neutral arbitrator instead of a
					judge or jury, may allow for more limited discovery than in
					court, and can be subject to very limited review by courts.
					Arbitrators can award the same damages and relief that a
					court can award. This Agreement to Arbitrate includes all
					claims arising out of or relating to any aspect of these
					Terms, whether based in contract, tort, statute, fraud,
					misrepresentation, or any other legal theory, and regardless
					of whether a claim arises during or after the termination of
					these Terms. THIS AGREEMENT TO ARBITRATE MEANS YOU AND
					DREAMWORLD WAIVE YOUR/ITS RESPECTIVE RIGHTS TO A JURY TRIAL.
					THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW
					OF AN ARBITRATION AWARD IS LIMITED.
					<br />
					THE AGREEMENT TO ARBITRATE AND THIS ARBITRATION PROVISION IS
					GOVERNED BY THE FEDERAL ARBITRATION ACT.
				</p>
				<p class="legal-text">
					<br />
					Arbitration. Any arbitration between you and DreamWorld will
					be settled under the Federal Arbitration Act and
					administered by the American Arbitration Association (“AAA”)
					under its Consumer Arbitration Rules (collectively, “AAA
					Rules”) as modified by this Section 14.6. You and we agree
					that the following rules will apply to the proceedings: (a)
					the arbitration will be heard and determined by a single,
					neutral arbitrator selected in accordance with the AAA
					Rules; (b) the arbitration will take place in the county
					where you live or at another mutually agreed location; (c)
					the arbitration will be conducted by telephone, online, or
					based solely on written submissions (at the choice of the
					party seeking relief); (d) the arbitration must not involve
					any personal appearance by the parties or witnesses (unless
					we and you agree otherwise), and provided that, Disputes
					that involve a claim of more than $10,000 USD must be
					resolved per the AAA’s rules about whether the arbitration
					hearing has to be in-person; and (e) any judgment on the
					arbitrator’s rendered award may be entered in any court with
					competent jurisdiction. All arbitration proceedings between
					the parties will be confidential unless otherwise agreed by
					the parties in writing. The arbitrator has exclusive
					authority to resolve any dispute relating to the
					interpretation, applicability, or enforceability of this
					binding arbitration agreement. A party electing arbitration
					must initiate proceedings by filing an arbitration demand
					with the AAA. The AAA Rules and filing forms are available
					online at www.adr.org, by calling the AAA at 1-800-778-7879.
				</p>
				<p class="legal-text">
					<br />
					Fees; Awards. If you commence arbitration in accordance with
					these Terms, DreamWorld will reimburse you for your payment
					of the filing fee, unless your claim is for more than
					$10,000 USD, in which case the payment of any fees will be
					decided by the AAA Rules. If the arbitrator finds that
					either the substance of your claim or the relief sought in
					the demand is frivolous or brought for an improper purpose
					(as measured by the standards set forth in Federal Rule of
					Civil Procedure 11(b)), then the payment of all fees will be
					governed by the AAA Rules. In that case, you agree to
					reimburse DreamWorld for all monies previously disbursed by
					it that are otherwise your obligation to pay under the AAA
					Rules. Regardless of the manner in which the arbitration is
					conducted, the arbitrator must issue a reasoned written
					decision sufficient to explain the essential findings and
					conclusions on which the decision and award, if any, are
					based. The arbitrator may make rulings and resolve disputes
					as to the payment and reimbursement of fees or expenses at
					any time during the proceeding and upon request from either
					party made within fourteen (14) days of the arbitrator’s
					ruling on the merits.
				</p>
				<p class="legal-text">
					<br />
					Opting-Out of Arbitration. If you do not wish to resolve
					disputes by binding arbitration, you may opt out of the
					Agreement to Arbitrate within 30 days after the date that
					you first agree to these Terms by sending a letter to
					DreamWorld Realities, Inc., Attention: Arbitration Opt-Out,{" "}
					<span data-mce-fragment="1">
						440 N BARRANCA AVE #2952 COVINA, CA 91723
					</span>
					, that specifies: your full legal name, the email address
					used to register an Account (if applicable), and a statement
					that you wish to opt out of arbitration (“Opt-Out Notice”).
					Once DreamWorld receives your Opt-Out Notice, the Agreement
					to Arbitrate will be void and the parties agree that the
					exclusive jurisdiction and venue described in Section 14.11
					will govern any action arising out of or related to these
					Terms. The remaining provisions of this Section 14 will not
					be affected by your Opt-Out Notice.
				</p>
				<p class="legal-text">
					<br />
					Exception to Arbitration. Notwithstanding anything in these
					Terms to the contrary to the extent you have in any manner
					violated or threatened to violate any of DreamWorld’s
					intellectual property rights, DreamWorld may seek injunctive
					or other appropriate relief in any state or federal court
					with competent jurisdiction in any country, including in the
					State of California, United States of America, without first
					engaging in arbitration or the informal dispute process set
					forth in this Section, and you hereby consent to the
					personal jurisdiction and exclusive venue in such courts. In
					addition, despite the Agreement to Arbitrate, nothing in
					these Terms will be deemed to waive, preclude, or otherwise
					limit the right of either party to: (a) bring an individual
					action in small claims court; (b) pursue an enforcement
					action through the applicable federal, state, or local
					agency if that action is available; or (c) seek injunctive
					relief in a court of law in aid of arbitration.
				</p>
				<p class="legal-text">
					<br />
					No Class Actions. YOU MAY ONLY RESOLVE DISPUTES WITH
					DREAMWORLD ON AN INDIVIDUAL BASIS, AND MAY NOT BRING A CLAIM
					AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS, CONSOLIDATED,
					OR REPRESENTATIVE ACTION. CLASS ARBITRATIONS, CLASS ACTIONS,
					PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH
					OTHER ARBITRATIONS AREN'T ALLOWED UNDER THESE TERMS.
				</p>
				<p class="legal-text">
					<br />
					Enforceability; Venue. If the Agreement to Arbitrate is
					found not to apply to your or our claim, you and DreamWorld
					agree that any judicial proceeding will be brought in the
					federal or state courts located in San Francisco County,
					California. Both you and DreamWorld consent to venue and
					personal jurisdiction there.
				</p>
				<p class="legal-text">
					<br />
					Time Limitation to Bring Claims. Notwithstanding any statute
					or law to the contrary, any claim or cause of action arising
					out of or related to your use of the Software (or any part
					thereof, including, without limitation, any Updates, New
					Versions, and/or Add-ons), and/or any , content, materials,
					services provided by DreamWorld to you hereunder must be
					filed within one (1) year after such claim or cause of
					action arose, otherwise that claim or cause of action will
					be barred forever.
				</p>
				<p class="legal-text">
					<br />
					COMMERCIAL ITEMS. The Software, and any related end user
					documentation are "Commercial Items", as that term is
					defined at 48 C.F.R. §2.101, consisting of "Commercial
					Computer Software" and "Commercial Computer Software
					Documentation", as such terms are used in 48 C.F.R. §12.212
					or 48 C.F.R. §227.7202, as applicable. Consistent with 48
					C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4,
					as applicable, the Commercial Computer Software and
					Commercial Computer Software Documentation will be licensed
					to U.S. Government end users (a) only as Commercial Items
					and (b) with only those rights as are granted to all other
					end users pursuant to these Terms.
				</p>
				<p class="legal-text">
					<br />
					MISCELLANEOUS CLAUSES
					<br />
					Neither the rights nor the obligations arising under these
					Terms are assignable by you, and any such attempted
					assignment or transfer shall be void and without effect.
					DreamWorld may freely assign, delegate or transfer these
					Terms, in whole or in part, including without limitation,
					its rights and/or obligations hereunder, without your
					consent.
				</p>
				<p class="legal-text">
					<br />
					These Terms are not intended to grant rights to anyone
					except you and DreamWorld, and in no event shall these Terms
					create any third party beneficiary rights.
				</p>
				<p class="legal-text">
					<br />
					Any waiver of any provision of these Terms must be in
					writing and executed by both parties. The failure of either
					party to exercise any right provided for by these Terms
					shall not be deemed a waiver of that right.
				</p>
				<p class="legal-text">
					<br />
					If any term or provision of these Terms is determined to be
					illegal, unenforceable, or invalid in whole or in part for
					any reason, that provision shall be stricken from these
					Terms and shall not affect the legality, enforceability or
					validity of the remainder of the provisions set forth in
					these Terms.
				</p>
				<p class="legal-text">
					<br />
					The parties acknowledge and agree that they are dealing with
					each other as independent contractors and nothing in these
					Terms and/or its performance shall be construed as creating
					a joint venture or agency between DreamWorld and you.
					<br />
					These Terms, the Privacy Notice, the Standards and
					Guidelines, and the Additional Terms, constitute the entire
					agreement between you and DreamWorld regarding the subject
					matter, and supersedes all prior oral or written agreements
					or communications with regard to the subject matter
					described.
				</p>
				<p class="legal-text">
					<br />
					Any notice to you may be provided by email and consent to
					receive such notices via email as set forth in Section 6.5.
				</p>
				<p class="legal-text">
					<br />
					The headings of Sections of these Terms are for convenience
					and are not to be used in interpreting these Terms. As used
					in these Terms, the word “including” means “including but
					not limited to.” You agree that these Terms will not be
					construed against DreamWorld by virtue of having drafted
					them. The official text of these Terms (and any notice
					submitted hereunder) will be in English. The parties
					acknowledge that they require that these Terms be drawn up
					in the English language only. In the event of any dispute
					concerning the construction or meaning of these Terms,
					reference will be made only to these Terms as written in
					English and not to any translation into another language.
				</p>
				<p class="legal-text">
					<br />
					QUESTIONS. Please feel free to contact us at
					support@playdreamworld.com if you have any questions about
					these Terms.
				</p>
			</div>
		</div>
	);
};
