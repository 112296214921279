import React, { useState } from "react";
import ReactDOM from "react-dom";
import { MainSectionHeader } from "../widget/MainSectionHeader";
import { StylishArrowDown } from "../widget/StylishArrowDown";
import { useMediaQuery, useTheme } from "@mui/material";
import useId from "@mui/material/utils/useId";

function clamp(num, min, max) {
	return num <= min ? min : num >= max ? max : num;
}

function PictureWText({ title, text, backgroundIndex, left }) {
	const theme = useTheme();
	const medMatchDownMd = useMediaQuery(theme.breakpoints.down("md"));
	const xlMatchDownMd = useMediaQuery(theme.breakpoints.down("xl"));
	const id = useId();

	const [triggeredAnim, setTriggeredAnim] = useState(false);

	var imgSrc;
	switch (backgroundIndex) {
		case 1:
			imgSrc =
				"../assets/img/new_design/BackgroundImages/DreamWorldForestRiver.png";
			break;
		case 2:
			imgSrc =
				"../assets/img/new_design/BackgroundImages/CopyPasteTower.png";
			break;
		case 3:
			imgSrc =
				"../assets/img/new_design/BackgroundImages/DreamWorldCombat.png";
			break;
		case 4:
			imgSrc =
				"../assets/img/new_design/BackgroundImages/Social_Image_2.png";
			break;
		default:
			imgSrc =
				"../assets/img/new_design/BackgroundImages/DreamWorldForestRiver.png";
			break;
	}

	const onButtonPress = () => {
		const nextItem = document.getElementById(id).nextSibling;
		var rect = nextItem.getBoundingClientRect();

		var scrollToPos = window.scrollY + rect.top + 20;
		// console.log(scrollToPos);
		window.scrollTo({ top: scrollToPos, behavior: "smooth" });
	};

	// Setup scroll listener
	window.addEventListener(
		"scroll",
		function (event) {
			// Get the current scrollY point
			// const sY = window.scrollY;
			const height = window.innerHeight;

			let imgContainer = document.getElementById(
				"img-container" + backgroundIndex
			);
			let rect = imgContainer.getBoundingClientRect();
			// console.log("TOP:" + rect.top);

			var percent = ((height - rect.top) * 100) / height;
			percent = clamp(percent, 0, 100);

			if (percent > 0 && !triggeredAnim) {
				setTriggeredAnim(true);
			}
			// console.log("Percent: " + percent);

			// // Compute the new padding value
			// const scale = 1 + additionalZoom - additionalZoom * (percent / 100);

			// // Update the dom with the new padding for the image container
			// if (imgContainer) {
			// 	document.getElementById("img" + backgroundIndex).style.scale =
			// 		scale;
			// }
		},
		{ passive: true }
	);

	function imgStyle() {
		if (medMatchDownMd) {
			return {
				zIndex: "-1",
				position: "relative",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
			};
		} else if (xlMatchDownMd) {
			return {
				zIndex: "-1",
				position: "relative",
			};
		} else {
			return {
				zIndex: "-1",
				position: "relative",
				height: "100%",
				width: "100%",
				objectFit: "cover",
			};
		}
	}

	return (
		<div id={id} className="picture-w-text">
			<div
				id={"img-container" + backgroundIndex}
				className={left ? "wrapper-left" : "wrapper-right"}
			>
				<img
					id={"img" + backgroundIndex}
					src={imgSrc}
					className={triggeredAnim ? "img-anim" : ""}
					style={imgStyle()}
				></img>
				<MainSectionHeader
					bodyText={text}
					className="main-section-header-left"
					mainSectionText={title}
					left={left}
				/>
			</div>

			<StylishArrowDown onButtonPress={onButtonPress} />
		</div>
	);
}

export default PictureWText;
